import moment from 'moment'

export function pluralize(label, time) {
    if(!time) {
      time = 0;
    }
    else if(Array.isArray(time)) {
      time = time.length;
    }
    else if(time instanceof Object) {
      time = Object.keys(time).length;
    }
    if (time === 1) return label
    return label + 's'
}

export function smart_capitalize(value) {
    if (value == null || value == undefined) return
    value = value.split('_').join(' ')
    return value.charAt(0).toUpperCase() + value.slice(1)
}

export function capitalize(value) {
    if (value == null || value == undefined) return
    return value.charAt(0).toUpperCase() + value.slice(1)
}

export function titleCase(value) {
    if (value == null || value == undefined) return
    return value.toLowerCase().split(' ').map(function(word) {
      return word.replace(word[0], word[0].toUpperCase());
    }).join(' ');
}

export function milliseconds(time) {
    return Math.floor(time / 1000)
}

export function html2Text(val) {
    const div = document.createElement('div')
    div.innerHTML = val
    return div.textContent || div.innerText
}

export function toThousandslsFilter(num) {
    return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

export function currency(value, abs = false) {
    if (typeof value !== "number" && isNaN(parseFloat(value))) return value;
    else value = parseFloat(value)

    if (abs) {
        value = Math.abs(value)
    }

    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
    return formatter.format(value);
}

export function dateformat(value, format) {
    if (value === null || value === undefined) return ""
    if (!format) format = 'MMM D, YYYY';

    if (typeof value === 'string' || value instanceof String) {
        var date = moment();

        if (value.includes("T") || value.split(' ').length == 2) {
            date = moment(value);
        } else {
            date = moment(value, 'YYYY-MM-DD');
        }
        if (!date.isValid()) return ""
        return date.format(format)
    } else {
        return moment(value).format(format)
    }
}

export function timestamp2Date(value, format) {
    if (value === null || value === undefined) return ""
    if (!format) format = 'MMM D, YYYY';

    return new Date(moment.unix(value).format(format));
}

export function daysDiff(value) {
    return moment(new Date(value)).diff(moment(), 'days');
}
