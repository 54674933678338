import { render, staticRenderFns } from "./ScrollBar.vue?vue&type=template&id=11c96735&scoped=true&"
import script from "./ScrollBar.vue?vue&type=script&lang=js&"
export * from "./ScrollBar.vue?vue&type=script&lang=js&"
import style0 from "./ScrollBar.vue?vue&type=style&index=0&id=11c96735&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11c96735",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('11c96735')) {
      api.createRecord('11c96735', component.options)
    } else {
      api.reload('11c96735', component.options)
    }
    module.hot.accept("./ScrollBar.vue?vue&type=template&id=11c96735&scoped=true&", function () {
      api.rerender('11c96735', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/layout/components/Sidebar/ScrollBar.vue"
export default component.exports