<template>
    <div class="menu-wrapper">
        <template v-for="item in routes" v-if="!item.hidden&&item.children">

            <template v-for="child in item.children" v-if="!item.hidden">
                <router-link :to="item.path+'/'+child.path" :key="child.name" v-if="child.meta && !child.meta.hidden">
                    <el-menu-item :index="item.path+'/'+child.path" :class="{'submenu-title-noDropdown' : !isNest}">
                        <!--<svgicon v-if="child.meta && child.meta.icon" :name="child.meta.icon"></svgicon>-->
                        <span v-if="child.meta && child.meta.title" slot="title">{{child.meta.title}}</span>
                    </el-menu-item>
                </router-link>
            </template>

        </template>
    </div>
</template>

<script>
    import '@/assets/icons'

    export default {
        name: 'SidebarItem',
        props: {
            routes: {
                type: Array
            },
            isNest: {
                type: Boolean,
                default: false
            }
        },
    }
</script>
