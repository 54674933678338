import Vue from 'vue';
import App from './App';
import router from './router';

import '@/auth/permission';

import VueGlobalVariable from 'vue-global-var';
import {EventBus} from '@/utils/event-bus.js';

Vue.use(VueGlobalVariable, {
    globals: {
        $bus: EventBus,
    },
});

/* ========== AUTH0 ============ */

import { Auth0Plugin } from "./auth";

Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENTID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

/* ========== COMPONENTS ============ */

Vue.config.productionTip = false;

import SvgIcon from 'vue-svgicon';
import Element from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';

import '@/assets/scss/index.scss'; // Global CSS
Vue.use(Element, {locale});
Vue.use(SvgIcon, {tagName: 'svgicon'});

/* ========== FILTERS ============ */

import * as filters from './filters'; // Global filters

Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key]);
});

/* ========== APP ============ */

new Vue({
    el: '#app',
    router,
    render: h => h(App),
});
