<template>
    <el-menu class="navbar" mode="horizontal">
        <el-tag class="notice" v-if="notice" type="danger"><i class="el-icon-warning" /> {{this.notice}}</el-tag>
        <el-dropdown class="settings-container" trigger="click">
            <div class="settings-wrapper">
                <svgicon name="settings"></svgicon>
            </div>
            <el-dropdown-menu class="user-dropdown" slot="dropdown">
                <el-dropdown-item>
                    <span @click="logout" style="display:block;">Logout</span>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </el-menu>
</template>

<script>
    
    export default {
        data() {
          return {
            notice: null,
          }
        },
        methods: {
            logout() {
                this.$auth.logout({
                    returnTo: `${process.env.VUE_APP_AUTH0_BASE_URL}/logout`
                });
            },
        },
        mounted() {
          this.$bus.$on('navbar-notice', (message) => {
            this.notice = message;
          });
        }
    };
</script>

<style lang="scss" scoped>
    hr {
        border: none;
        height: 1px;
        background-color: #EEF1F6;
    }

    .navbar {
        height: 50px;
        line-height: 50px;
        border-radius: 0px !important;

        .notice {
          margin-left: 20px;
        }

        .screenfull {
            position: absolute;
            right: 90px;
            top: 16px;
            color: red;
        }

        .settings-container {
            height: 50px;
            display: inline-block;
            position: absolute;
            right: 10px;

            .settings-wrapper {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                width: 50px;

                svg {
                    width: 35px;
                    height: 35px;
                }
            }
        }
    }
</style>
