import router from '../router';
import {validateRoute} from '@/utils/validate';

import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

import { getInstance } from "./index";

const whiteList = ['/login','/logout'];

router.beforeEach((to, from, next) => {
    NProgress.start();

    const authService = getInstance();

    const fn = () => {
        // If the user is authenticated, continue with the route
        if (authService.isAuthenticated) {
            return next();
        }

        // otherwise, check if this is a whitelisted page
        if (validateRoute(to.path, whiteList)) {
            return next();
        } else {
            // otherwise, log in
            authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
        }
    };

    // If loading has already finished, check our auth state using `fn()`
    if (!authService.loading) {
        return fn();
    }

    // Watch for the loading property to change before we check isAuthenticated
    authService.$watch("loading", loading => {
        if (loading === false) {
            return fn();
        }
    });
});

router.afterEach(() => {
    NProgress.done();
});