import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import Layout from '../views/layout/Layout';

export const routesMap = [
    {
        path: '/login',
        component: () => import('@/views/login/index'),
        hidden: true,
    },
    {
        path: '/logout',
        component: () => import('@/views/logout/index'),
        hidden: true,
    },
    {
        path: '/auth/callback',
        //redirect: 'orgs',
        redirect: 'activities',
        hidden: true,
    },
    {
        path: '/404',
        component: () => import('@/views/404'),
        hidden: true,
    },
    {
        path: '*',
        redirect: '/404',
        hidden: true,
    },
    {
        path: '',
        //redirect: 'orgs',
        redirect: 'activities',
        hidden: true,
    },

    {
        path: '',
        component: Layout,
        children: [
          {
              path: 'orgs',
              component: () => import('@/views/orgs/index'),
              name: 'orgs',
              meta: {title: 'Organisations', icon: 'user'},
          },
          {
              path: 'org/:org_id',
              component: () => import('@/views/orgs/profile'),
              name: 'org-profile',
              meta: {title: 'Organisation', icon: 'user', hidden: true}
          },
          {
              path: 'activities',
              component: () => import('@/views/activities'),
              name: 'activities',
              meta: {title: 'Activities', icon: 'user'}
          },
          {
              path: 'activity/new',
              component: () => import('@/views/activities/profile'),
              name: 'activity-new',
              meta: {title: 'New activity', icon: 'user',hidden: true}
          },
          {
              path: 'activity/:activity_id',
              component: () => import('@/views/activities/profile'),
              name: 'activity',
              meta: {title: 'Activity', icon: 'user',hidden: true}
          },
          {
              path: 'modules',
              component: () => import('@/views/modules/index'),
              name: 'modules',
              meta: {title: 'Learning Modules', icon: 'user'},
          },
          {
              path: 'module/new',
              component: () => import('@/views/modules/profile'),
              name: 'module-new',
              meta: {title: 'New learning module', icon: 'user',hidden: true}
          },
          {
              path: 'module/:module_id',
              component: () => import('@/views/modules/profile'),
              name: 'module',
              meta: {title: 'Learning module', icon: 'user',hidden: true}
          },
          /*
          {
              path: 'info',
              component: () => import('@/views/info/index'),
              name: 'info',
              meta: {title: 'Information Pages', icon: 'user'},
          }
          */
        ],
    },
];

export default new Router({
    mode: 'history',
    routes: routesMap,
});
