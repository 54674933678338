var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-wrapper" },
    [
      _c("sidebar", { staticClass: "sidebar-container" }),
      _c(
        "div",
        { staticClass: "main-container" },
        [_c("navbar"), _c("app-main")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }