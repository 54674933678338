<template>
    <scroll-bar>
        <div class="logo">
            <img :src="logo">
        </div>
        <el-menu mode="vertical"
                 :show-timeout="200"
                 :default-active="$route.path"
                 background-color="#304156"
                 text-color="#bfcbd9"
                 active-text-color="#409EFF">
            <sidebar-item :routes="routes"/>
        </el-menu>
    </scroll-bar>
</template>

<script>
    import SidebarItem from './SidebarItem';
    import ScrollBar from './ScrollBar';
    import logo from '@/assets/logo.svg';

    export default {
        components: {SidebarItem, ScrollBar},
        data() {
            return {
                logo,
            };
        },
        computed: {
            routes() {
                return this.$router.options.routes;
            },
        },
    };
</script>

<style lang="scss" scoped>

    .logo {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

</style>
